<template>
	<div class="order-page">
		<span v-if="state === 'success'" class="status status-success">
			✓
		</span>
		<span v-else-if="state === 'pending'" class="status status-pending">
			?
		</span>
		<span v-else class="status status-error">
			✕
		</span>
		<div v-if="textContent" v-html="textContent" class="content">
		</div>
		<div v-else class="content">
			<slot/>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "../../event-bus";

	export default {
		name: "Order",
		props: {
			state: {
				default: "success"
			},
			simpleText: {
				default: null
			}
		},
		mounted() {
			EventBus.$emit("changeTheme", "white");
		},
    computed: {
      textId() {
        if (this.$route.query.predplatne ?? null) {
          const id = `${this.simpleText}-subscription`;
          if (this.$api.texts[id]) {
            return id;
          }
        }

        return this.simpleText;
      },
      textContent() {
        if (!this.textId || !this.$api.texts[this.textId]) {
          return null;
        }
        const text = this.$api.texts[this.textId].raw;

        const link = this.$route.query.redirect?? null;
        if (!link) {
          return text;
        }

        return text.replace('_REDIRECT_LINK_START_', `<a href="${link}">`)
            .replace('_REDIRECT_LINK_END_', `</a>`);
      }
    }
	}
</script>